<template>
    <div class="up_monitor_content_inner">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title p-mr-3">Website Logs</h2>
                <!-- <form class="dropdown_form"> -->
                        <div style="display: inline-block; text-align: justify;" class="up_header_field log_form_wrapper">
                        <div class="dropdown_wrapper">
                            <Dropdown v-model="selectedWebsite.websiteId" :options="allWebsiteData.data" id="u_sitename"
                                optionLabel="siteUrl" optionValue="uuid" placeholder="Select Website"
                                @change="fetchWebsiteLogFileData()" />
                        </div>
                        <div class="log_file_button" style="padding: 0 0 0 20px">
                            <button v-if="selectedWebsite.websiteId" style="display: inline-block; text-align: justify;" class="p-button p-component p-button-primary p-mr-2" type="button" @click="downloadFile()"><!----><span class="p-button-label">Download</span><!----><span class="p-ink"></span></button>
                        </div>
                        </div>
                <!-- </form> -->
            </div>
            <!--- Show Website Table Start --->
                <!-- <DataTable :v-for="websiteLogFileData.data" class="p-datatable p-mb-2" :paginator="true" stripedRows :value="websiteLogFileData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :rowHover="true" :loading="allWebsiteData.loading" responsiveLayout="scroll" removableSort :filters="filters" :dataKey="uuid">
                    <template #loading>
                        Loading Log data. Please wait.
                    </template>
                    <template #header>
                    </template>
                    <template #empty>
                        No Website selected.
                    </template>

                    <Column field="siteName" header="Website" headerStyle="width: 25rem" :sortable="false">
                        <template #body="slotProps">
                            {{slotProps.data.siteName}}
                        </template>
                    </Column>

                    <Column field="siteUrl" header="Website URL" headerStyle="width: 35rem" :sortable="false">
                        <template #body="slotProps">
                            {{slotProps.data.siteUrl}}
                        </template>
                    </Column>

                    <Column field="createdAt" header="Updated On" :sortable="false" headerStyle="width: 15rem" style="text-align: center">
                        <template #body="slotProps">
                        {{usFormatDate(slotProps.data.updatedAt)}}
                        </template>
                    </Column>

                    <Column field="action" header="Actions" style="text-align: center">
                        <template #body="slotProps">                            
                            <div class="manage_sites_action">
                                <Button type="submit"  icon="pi pi-download" class="p-button-plain p-button-rounded p-component p-button-sm" v-tooltip.left="'Download Log File'" @click="downloadFile(slotProps.data.fileUrl)"></Button>
                            </div>                            
                        </template>
                    </Column>
                </DataTable> -->
                <!--- Show Website Table End --->
                
            <div class="manage_sites_action site_log_wrapper">
                <div class="textarea_wrapper">
                    <div :class="selectedWebsite.loading"><ProgressSpinner /></div>
                
                    <Textarea v-model="fileContent.text" v-if="selectedWebsite.websiteId" readonly style="resize: none;" rows="17" cols="150">                  
                    </Textarea>
                </div>
                
                
                                
            </div>
        </div>
    </div>

</template>

<script>
import { userPortalWebsiteScanLogs } from '../../../../config/appUrls'
import { imageUrlFrontEnd, apiKey } from '../../../../config/constant';
import { useStore } from 'vuex';
import { usFormatDate, camelToWords, formatDate } from "../../../../helper/utility/utility";
import axios from "axios";
// import ScrollPanel from 'primevue/scrollpanel';
import { onBeforeMount, reactive } from 'vue';
//const FileSaver = require('file-saver');

export default {
    name: 'ScanLogs',
    components: {
    },
    setup() {
        //Vuex store data call fucntion
        const store = useStore();

        const allWebsiteLogFileData = reactive({
            loadingDownload: false
        })

        const fileContent = reactive({
            text: ''
        })

        const selectedWebsite = reactive({
            websiteId: null,
            url: '',
            loading: 'beforeLoading'
        })
        const allWebsiteData = reactive({
            data: '',
            status: ''
        });
        
        const websiteLogFileData = reactive({
            data: '',
            status: ''
        })

        onBeforeMount(() => {
            fetchWebsiteList();
        });

        //Website list for Website search dropdown
        const fetchWebsiteList = async () => {
            const response = await axios.get( userPortalWebsiteScanLogs, {
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            allWebsiteData.data = response.data.data//.website//.filter(x=>x.deletedAt === null);

        }

        function dateDiff(startingDate, endingDate) {
            var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
            if (!endingDate) {
                endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
            }
            var endDate = new Date(endingDate);
            if (startDate > endDate) {
                var swap = startDate;
                startDate = endDate;
                endDate = swap;
            }
            var startYear = startDate.getFullYear();
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            var yearDiff = endDate.getFullYear() - startYear;
            var monthDiff = endDate.getMonth() - startDate.getMonth();
            if (monthDiff < 0) {
                yearDiff--;
                monthDiff += 12;
            }
            var dayDiff = endDate.getDate() - startDate.getDate();
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--;
                } else {
                    yearDiff--;
                    monthDiff = 11;
                }
                dayDiff += daysInMonth[startDate.getMonth()];
            }
            if (yearDiff === 0 && monthDiff > 0) {
                return monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            } else if (yearDiff === 0 && monthDiff === 0) {
                return dayDiff + (dayDiff > 1 ? ' Days ago' : ' Day ago ');
            } else {
                return yearDiff + (yearDiff > 1 ? ' Years ' : ' Year ') + monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            }
        }

        const fetchWebsiteLogFileData = async() => {
            const response = await axios.get( userPortalWebsiteScanLogs, {
                params: {
                    uuid: selectedWebsite.websiteId
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }
            });
            if (response.data.status === 200){
                selectedWebsite.loading = 'afterLoading';
                selectedWebsite.url = response.data.data[0].fileUrl;
                websiteLogFileData.data = response.data.data;
                websiteLogFileData.status = response.data.status;
                const r2 = await axios.get(selectedWebsite.url);
                fileContent.text = r2.data;
            }
           
        }

        const downloadFile = async() => {
            allWebsiteLogFileData.loadingDownload = !allWebsiteLogFileData.loadingDownload;
            window.open(selectedWebsite.url);
        }

        return {
            imageUrlFrontEnd,
            allWebsiteData,
            websiteLogFileData,
            formatDate,
            usFormatDate,
            dateDiff,
            camelToWords,
            fetchWebsiteLogFileData,
            selectedWebsite,
            downloadFile,
            fileContent
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/Monitor/monitor.css';
@import '../../../../assets/style/component/userportal/pages/websiteScanLogs/WebsiteScanLogs.css';
.dropdown_form {
    width: 100%;
}
.log_form_wrapper {
    display: flex!important;
    justify-content: space-between;
    align-items: center;
}
.dropdown_wrapper {
    width: 100%;
    max-width: 260px;
}
.up_page_title {
    max-width: 350px;
    margin-right: 20px;
}
</style>